// This file was automatically generated from admin.merchant.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.MerchantList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Admin.MerchantListBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.MerchantListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="merchantlist-page"><div class="content-header">Merchants</div><div class="right vpadding"><a id="merchant-add-new-link" class="ajaxify" href="/admin/merchant/create">+ create new</a></div><div>');
  if (opt_data.content.error_msg) {
    output.append('<div>', soy.$$escapeHtml(opt_data.content.error_msg), '</div>');
  } else {
    var merchantList931 = opt_data.content.merchants;
    var merchantListLen931 = merchantList931.length;
    for (var merchantIndex931 = 0; merchantIndex931 < merchantListLen931; merchantIndex931++) {
      var merchantData931 = merchantList931[merchantIndex931];
      if (merchantIndex931 % 2 == 0) {
        Nightloop.Templates.Admin.MerchantRecord(soy.$$augmentData(opt_data, {merchant: merchantData931, rowtype: 'row-even'}), output);
      } else {
        Nightloop.Templates.Admin.MerchantRecord(soy.$$augmentData(opt_data, {merchant: merchantData931, rowtype: 'row-odd'}), output);
      }
    }
  }
  output.append('</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.MerchantRecord = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="container entity-record ', soy.$$escapeHtml(opt_data.rowtype), '"><a class="ajaxify float-right merchant-edit-link" href="/admin/merchant/', soy.$$escapeHtml(opt_data.merchant.id), '/edit">[edit merchant]</a> <span class="merchant-name-only">', soy.$$escapeHtml(opt_data.merchant.name), '</span> </div>');
  return opt_sb ? '' : output.toString();
};
