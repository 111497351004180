// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList17148 = opt_data.frozenFollowers;
  var followerListLen17148 = followerList17148.length;
  for (var followerIndex17148 = 0; followerIndex17148 < followerListLen17148; followerIndex17148++) {
    var followerData17148 = followerList17148[followerIndex17148];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData17148.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData17148.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList17156 = opt_data.followers;
  var followerListLen17156 = followerList17156.length;
  for (var followerIndex17156 = 0; followerIndex17156 < followerListLen17156; followerIndex17156++) {
    var followerData17156 = followerList17156[followerIndex17156];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData17156.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData17156.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData17156.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData17156.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
