// This file was automatically generated from admin.venue.dataimport.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenueBulkDataImport = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.VenueBulkDataImportBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueBulkDataImportBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-data-import">');
  if (opt_data.content.error_msgs) {
    output.append('<div class="error-message">');
    var errorList2483 = opt_data.content.error_msgs;
    var errorListLen2483 = errorList2483.length;
    for (var errorIndex2483 = 0; errorIndex2483 < errorListLen2483; errorIndex2483++) {
      var errorData2483 = errorList2483[errorIndex2483];
      output.append('<span style="display:block">', soy.$$escapeHtml(errorData2483), '</span>');
    }
    output.append('</div>');
  }
  if (opt_data.content.success_msgs) {
    output.append('<div class="gold-message">');
    var successList2492 = opt_data.content.success_msgs;
    var successListLen2492 = successList2492.length;
    for (var successIndex2492 = 0; successIndex2492 < successListLen2492; successIndex2492++) {
      var successData2492 = successList2492[successIndex2492];
      output.append('<span style="display:block">', soy.$$escapeHtml(successData2492), '</span>');
    }
    output.append('</div>');
  }
  output.append('<br><div class="content-header">Bulk Data Import Tool</div><br><div class="content-header">Venue: ', soy.$$escapeHtml(opt_data.content.venue.name), '</div><br><div class="content-header">Upload Data File</div><form id="bulk-data-import" method="post" enctype="multipart/form-data" style="margin: 20px"><input name="file" type="file"><br><br><select id="select-adapter" name="adapter" form="bulk-data-import" required><option value="">Select import type</option>');
  var adapterList2501 = opt_data.content.adapters;
  var adapterListLen2501 = adapterList2501.length;
  for (var adapterIndex2501 = 0; adapterIndex2501 < adapterListLen2501; adapterIndex2501++) {
    var adapterData2501 = adapterList2501[adapterIndex2501];
    output.append('<option value="', soy.$$escapeHtml(adapterData2501), '">', soy.$$escapeHtml(adapterData2501), '</option>');
  }
  output.append('</select><span id="data-type-invalid" style="margin: 0 7px; display: none; color: red;">Must select a data type</span><br><br><label style="display: block; margin-bottom: 5px;" for="additional-parameters">Additional Parameters</label><textarea style="width: 240px; height: 50px;"id="additional-parameters" name="additional-parameters"></textarea><br><br><input type="submit" value="Upload"></form><script>var params = {rurl: location.protocol + \'//\' + location.host + \'/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/data/upload\'}; $.get( \'/upload-url\', params, (result) => {$(\'#bulk-data-import\').attr(\'action\', result.upload_url);});<\/script><br><br><div class="content-header">Data Import Files</div><br><style>td {text-align: center;}</style><table style="width:100%"><tr><th>File</th><th>Import Type</th><th>Check Header</th><th>Validate Data</th><th>Import Data</th></tr>');
  var file_detailsList2511 = opt_data.content.file_details_list;
  var file_detailsListLen2511 = file_detailsList2511.length;
  for (var file_detailsIndex2511 = 0; file_detailsIndex2511 < file_detailsListLen2511; file_detailsIndex2511++) {
    var file_detailsData2511 = file_detailsList2511[file_detailsIndex2511];
    output.append('<tr><td>', soy.$$escapeHtml(file_detailsData2511.filename), '</td><td>', soy.$$escapeHtml(file_detailsData2511.adapter), '</td><td><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/data/', soy.$$escapeHtml(file_detailsData2511.validate_id), '/headers">Link</a></td><td>', (file_detailsData2511.awaiting_validation) ? '<a href="/admin/venue/' + soy.$$escapeHtml(opt_data.content.venue.id) + '/data/' + soy.$$escapeHtml(file_detailsData2511.validate_id) + '/validate">Kick Off</a>' : '<a href="/admin/venue/' + soy.$$escapeHtml(opt_data.content.venue.id) + '/data/' + soy.$$escapeHtml(file_detailsData2511.validate_id) + '/report">Report</a>', '</td><td>', (file_detailsData2511.awaiting_validation) ? '<span>Must validate first</span>' : (file_detailsData2511.awaiting_import) ? '<a href="/admin/venue/' + soy.$$escapeHtml(opt_data.content.venue.id) + '/data/' + soy.$$escapeHtml(file_detailsData2511.import_id) + '/import">Kick Off</a>' : '<a href="/admin/venue/' + soy.$$escapeHtml(opt_data.content.venue.id) + '/data/' + soy.$$escapeHtml(file_detailsData2511.import_id) + '/report">Report</a>', '</td></tr>');
  }
  output.append('</table>', (opt_data.content.no_data) ? '<div style="background-color: lightgray; text-align: center; font-size: 15px; padding: 12px;">No files have been uploaded.</div>' : '', '</div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueImportFileHeaderDetails = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Admin.VenueImportFileHeaderDetailsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueImportFileHeaderDetailsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-data-import-headers"><div class="content-header">Import File Header Fields</div><style>.descriptors {display: inline-block; margin: 7px 0; font-size: 14px;}td {text-align: center;}.headers {padding: 7px; font-size: 14px; font-weight: 600; display: table-cell; vertical-align: top; width: 50%;}.header-desc {font-style: italic; height: 51px;}.valid-fields {background-color: #f3f3f3;}.invalid-fields {background-color: #f3f3f3;}</style><br><span class=\'descriptors\'>Venue Name:  ', soy.$$escapeHtml(opt_data.content.venue.name), '</span><br><span class=\'descriptors\'>File Name:  ', soy.$$escapeHtml(opt_data.content.filename), '</span><br><span class=\'descriptors\'>Import type:  ', soy.$$escapeHtml(opt_data.content.adapter), '</span><br>');
  if (opt_data.content.missing_fields) {
    output.append('<div style="font-style: italic; margin: 30px 0; font-size: 15px;"><span>Missing fields</span><p>Below is a list of the fields that are missing from the import file. These fields are required, the import cannot proceed without the fields.</p><p style="color: red;">');
    var fieldList2571 = opt_data.content.missing_fields;
    var fieldListLen2571 = fieldList2571.length;
    for (var fieldIndex2571 = 0; fieldIndex2571 < fieldListLen2571; fieldIndex2571++) {
      var fieldData2571 = fieldList2571[fieldIndex2571];
      output.append((! (fieldIndex2571 == fieldListLen2571 - 1)) ? '<span>' + soy.$$escapeHtml(fieldData2571) + '</span>,  ' : '<span>' + soy.$$escapeHtml(fieldData2571) + '</span>');
    }
    output.append('</p></div>');
  }
  output.append('<div style="display: table-cell; margin-top: 5px;">');
  if (opt_data.content.used_fields) {
    output.append('<div class=\'headers valid-fields\'><h6>Valid Fields</h6><p class=\'header-desc\'>These fields from the import file will be used in the import process.</p><table style="margin: 0 auto;"><tr><th>Valid File Fields</th><tr>');
    var fieldList2587 = opt_data.content.used_fields;
    var fieldListLen2587 = fieldList2587.length;
    for (var fieldIndex2587 = 0; fieldIndex2587 < fieldListLen2587; fieldIndex2587++) {
      var fieldData2587 = fieldList2587[fieldIndex2587];
      output.append('<tr><td>', soy.$$escapeHtml(fieldData2587), '</td></tr>');
    }
    output.append('</table></div>');
  }
  if (opt_data.content.unused_fields) {
    output.append('<div class=\'headers invalid-fields\'><h6>Invalid Fields</h6><p class=\'header-desc\'>These fields from the import file do not have corresponding fields in the Sevenrooms system, and will not be pulled into the system when the file is imported.</p><table style="margin: 0 auto;"><tr><th>Invalid File Fields</th><tr>');
    var fieldList2596 = opt_data.content.unused_fields;
    var fieldListLen2596 = fieldList2596.length;
    for (var fieldIndex2596 = 0; fieldIndex2596 < fieldListLen2596; fieldIndex2596++) {
      var fieldData2596 = fieldList2596[fieldIndex2596];
      output.append('<tr><td>', soy.$$escapeHtml(fieldData2596), '</td></tr>');
    }
    output.append('</table></div>');
  }
  output.append('</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ImportJobDetails = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Admin.ImportJobDetailsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ImportJobDetailsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-data-import-headers"><div class="content-header">Import Job</div><style>.summary-items {display: inline-block; margin: 3px 0; width: 50%;}</style><h3>', soy.$$escapeHtml(opt_data.content.venue.name), '</h3><h3>', soy.$$escapeHtml(opt_data.content.filename), '</h3><h3>', soy.$$escapeHtml(opt_data.content.job_type), '</h3><h6>Import job progress bar</h6><progress value="', soy.$$escapeHtml(opt_data.content.progress[0]), '" max="', soy.$$escapeHtml(opt_data.content.progress[1]), '"></progress><div style="background-color: #e4e3e3; padding: 7px; margin: 7px 0;"><h6 style="margin-bottom:7px;">Job Summary</h6>');
  var sumList2618 = opt_data.content.summary;
  var sumListLen2618 = sumList2618.length;
  for (var sumIndex2618 = 0; sumIndex2618 < sumListLen2618; sumIndex2618++) {
    var sumData2618 = sumList2618[sumIndex2618];
    output.append('<div class="summary-items">', soy.$$escapeHtml(sumData2618), '</div>');
  }
  output.append('</div><div style="background-color: #f9f8f8; padding: 7px; margin: 7px 0;"><h6 style="margin-bottom:7px;">Row Details</h6>');
  var detailList2624 = opt_data.content.details;
  var detailListLen2624 = detailList2624.length;
  for (var detailIndex2624 = 0; detailIndex2624 < detailListLen2624; detailIndex2624++) {
    var detailData2624 = detailList2624[detailIndex2624];
    output.append((detailData2624[0] == 'ERROR') ? '<div style="background-color: #ffbfbf;">' : (detailData2624[0] == 'WARNING') ? '<div style="background-color: #ffeebf;">' : '<div style="background-color: #d0e6fd;">', soy.$$escapeHtml(detailData2624[1]), '</div>');
  }
  output.append('</div></div>');
  return opt_sb ? '' : output.toString();
};
