// This file was automatically generated from admin.municipality.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.MunicipalityList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.MunicipalityListBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.MunicipalityListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="content-header">Municipalities</div><ul>');
  var municipalityList1142 = opt_data.content.municipalities;
  var municipalityListLen1142 = municipalityList1142.length;
  for (var municipalityIndex1142 = 0; municipalityIndex1142 < municipalityListLen1142; municipalityIndex1142++) {
    var municipalityData1142 = municipalityList1142[municipalityIndex1142];
    output.append((municipalityIndex1142 % 2 == 0) ? '<li><a class="ajaxify muni-edit-link row-even" href="/admin/municipality/edit/' + soy.$$escapeHtml(municipalityData1142.id) + '">' + soy.$$escapeHtml(municipalityData1142.name) + '/' + soy.$$escapeHtml(municipalityData1142.state) + '/' + soy.$$escapeHtml(municipalityData1142.country) + '</a></li>' : '<li><a class="ajaxify muni-edit-link row-odd" href="/admin/municipality/edit/' + soy.$$escapeHtml(municipalityData1142.id) + '">' + soy.$$escapeHtml(municipalityData1142.name) + '/' + soy.$$escapeHtml(municipalityData1142.state) + '/' + soy.$$escapeHtml(municipalityData1142.country) + '</a></li>');
  }
  output.append('</ul><a id="muni-add-link" class="ajaxify" href="/admin/municipality/edit/">add municipality</a>');
  return opt_sb ? '' : output.toString();
};
